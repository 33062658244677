import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { OverlayModule } from '@angular/cdk/overlay'
import { CdkMenuModule } from '@angular/cdk/menu'
import { CdkAccordionModule } from '@angular/cdk/accordion'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSliderModule } from '@angular/material/slider'
import { MatRippleModule } from '@angular/material/core'
import { MatTooltipModule, } from '@angular/material/tooltip'
import { MatStepperModule } from '@angular/material/stepper'
import { MatMenuModule } from '@angular/material/menu'
import { MatRadioModule } from '@angular/material/radio'
import { MatTabsModule } from '@angular/material/tabs'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatChipsModule } from '@angular/material/chips'

import { FormlyModule } from '@ngx-formly/core'
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'
import { AgGridModule } from 'ag-grid-angular'
import { GridsterModule } from 'angular-gridster2'
import { AngularResizeElementModule } from 'angular-resize-element'
// import { DUCModule } from '@dataunveil/ducharts'

import { SharedComponentModule } from './../shared/shared.module'
import { ChartsModule } from '../shared/charts/charts.module'
import { MatComponentsModule } from '../../mat-components.module'
import { MyInterceptor } from 'src/app/services/my.interceptor'
import { WidgetService } from '../../services/widget.service'

import { WidgetDirective } from "./widget.directive";
import { WidgetDataGridComponent } from "./data-grid/data-grid.component";
import { Widget1kpiCardComponent } from "./card1kpi/card1kpi.component";
import { Widget2kpiCardComponent } from "./card2kpi/card2kpi.component";
import { GridPageComponent } from "./grid-page/grid-page.component";
import { WidgetDonutChartCardComponent } from "./donut-chart-card/donut-chart-card.component";
import { WidgetHtmlNotesComponent } from "./html-notes/html-notes.component";
import { WidgetTextNotesComponent } from "./text-notes/text-notes.component";
import { FilterComponent } from "./filter/filter.component";
import { FilterBadgesComponent } from "./filter-badges/filter-badges.component";
import { WidgetHeatmapChartComponent } from "./heatmap-chart/heatmap-chart.component";
import { WidgetLineChartComponent } from "./line-chart-card/line-chart-card.component";
import { WidgetLinkCardComponent } from "./link-card/link-card.component";
import { WidgetBarChartCardComponent } from "./bar-chart-card/bar-chart-card.component";
import { WidgetTimeCycleComponent } from "./time-cycle/time-cycle.component";
import { SanitizeHtmlPipe } from "src/app/pipe/sanitize-html.pipe";
import { WidgetPivotGridComponent } from "./pivot-Grid/pivot-grid.component";
import { PatientGridListComponent } from "./patient-grid-list/patient-grid-list.component";
import { WidgetBubbleChartComponent } from "./bubble-chart-card/bubble-chart-card.component";
import { PatientPipelineComponent } from './patient-pipeline/patient-pipeline.component';
import { WidgetSparklineBarChartComponent } from "./sparklineBar-chart/sparklineBar-chart-card.component";
import { InfoIconComponent } from './info-icon/info-icon.component';
import { PrescriberCardComponent } from './prescriber-card/prescriber-card.component';
import { PatientCardComponent } from "./patient-card/patient-card.component";
import { PatientJourneyCardComponent } from "./patient-journey-card/patient-journey-card.component";
import { PendingPatientTherapyCardComponent } from './pending-patient-therapy-card/pending-patient-therapy-card.component';
import { PrescriberOfficesListComponent } from './prescriber-offices-list/prescriber-offices-list.component';
import { WidgetComboBarChartComponent } from "./comboBar-chart-card/comboBar-chart-card.component";
import { ShowByCardComponent } from './show-by-card/show-by-card.component';
import { WidgetPrescriberAccordinComponent } from "./prescriber-accordin/prescriber-accordin.component";
import { FilterTextPipe } from "src/app/pipe/filter.pipe";
import { WordCloudComponent } from './word-cloud/word-cloud.component';
import { GroupedHbarComponent } from './grouped-hbar/grouped-hbar.component';
import { AreaLineChartComponent } from './area-line-chart/area-line-chart.component';
import { SmallBarChartComponent } from './small-bar-chart/small-bar-chart.component';
import { StatusCodesComponent } from "./status-codes/status-codes.component";
import { HelpCenterComponent } from './help-center/help-center.component';
import { BulletChartCardComponent } from './bullet-chart-card/bullet-chart-card.component';
import { LoaderToastModuleModule } from "../loader-toast-module/loader-toast-module.module";
import { HierarchicalGridComponent } from "./hierarchical-grid/hierarchical-grid.component";
import { SlopeChartComponent } from './slope-chart/slope-chart.component';
import { HBarChartComponent } from './h-bar-chart/h-bar-chart.component';
import { BasicHeatmapCardComponent } from './basic-heatmap-card/basic-heatmap-card.component';
import { KpiHeatMapCardComponent } from './kpi-heat-map-card/kpi-heat-map-card.component';
import { MultiLinechartComponent } from './multi-linechart/multi-linechart.component';
import { ControlChartCardComponent } from './control-chart-card/control-chart-card.component';
import { HelpInformationComponent } from './help-information/help-information.component';
import { TreeMenuComponent } from "./tree-menu/tree-menu.component";
import { PaymentRecordDetailsComponent } from "./payment-record-details/payment-record-details.component";
import { StackedAraeBarComponent } from './stacked-arae-bar/stacked-arae-bar.component';
import { CustomKpiComponent } from './custom-kpi/custom-kpi.component';
import { UnderScoreDetailsComponent } from "./payment-record-details/under-score-details.component";
import { CustomCurrencyPipe, NumberFormatPipe } from "src/app/pipe/number-format.pipe";
import { WidgetSummaryGridComponent } from "./summary-grid/summary-grid.component";
import { WidgetDetailCardComponent } from "./detail-card/detail-card.component";
import { TerritoryMapComponent } from './territory-map/territory-map.component';
import { PieChartCardComponent } from "./pie-chart-card/pie-chart-card.component";
import { VennChartCardComponent } from './venn-chart-card/venn-chart-card.component';
import { KpiAlertsComponent } from './kpi-alerts/kpi-alerts.component';
import { DispenseMapComponent } from './dispense-map/dispense-map.component';
import { TooltipComponent } from "./dispense-map/tooltip.component";
import { DataGridEditingComponent } from './data-grid-editing/data-grid-editing.component';
import { ClusterBarchartCardComponent } from './cluster-barchart-card/cluster-barchart-card.component';
import { TimeToTherapy } from "./time-to-therapy/time-to-therapy";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { searchTextPipe } from "src/app/pipe/serach-filter.pipe";
import { IRHeatMapCardComponent } from './ir-heat-map-card/ir-heat-map-card.component';
import { ValidationInputComponent } from "./data-grid-editing/validation-input.component";
import { BasicGridListComponent } from './basic-grid-list/basic-grid-list.component';
import { ValueBtnComponent } from "./basic-grid-list/value-btn.component";
import { NestedWidgetComponent } from './nested-widget/nested-widget.component';
import { AlertButton } from "./alert-button/alert-button";
import { FilterButton } from "./filter-button/filter-button";
import { StatusOverviewWidget } from './status-overview/status-overview'
import { ProgressIndefinite } from '../shared/progress-indicator/indefinite'
import { AlertsSubscriptionModule } from "./alerts/alerts.module";
import { WidgetPaymentSummaryComponent } from './payment-summary/payment-summary.component'
import { OperationMonitorComponent } from './operation-monitor/operation-monitor.component';
import {TextFieldModule} from '@angular/cdk/text-field';
import { InitcapPipe } from 'src/app/pipe/init-cap.pipe';
import { CommentsGridComponent } from './hierarchical-grid/comments-grid/comments-grid/comments-grid.component'

@NgModule({
  declarations: [
    GridPageComponent,
    Widget1kpiCardComponent,
    Widget2kpiCardComponent,
    WidgetDonutChartCardComponent,
    WidgetLinkCardComponent,
    SanitizeHtmlPipe,
    CustomCurrencyPipe,
    NumberFormatPipe,
    WidgetHtmlNotesComponent,
    WidgetHeatmapChartComponent,
    WidgetDataGridComponent,
    WidgetPivotGridComponent,
    WidgetLineChartComponent,
    WidgetBarChartCardComponent,
    WidgetTimeCycleComponent,
    FilterComponent,
    FilterBadgesComponent,
    PatientCardComponent,
    PatientJourneyCardComponent,
    PatientGridListComponent,
    WidgetBubbleChartComponent,
    PatientPipelineComponent,
    WidgetSparklineBarChartComponent,
    InfoIconComponent,
    PrescriberCardComponent,
    PendingPatientTherapyCardComponent,
    PrescriberOfficesListComponent,
    WidgetComboBarChartComponent,
    ShowByCardComponent,
    WidgetPrescriberAccordinComponent,
    FilterTextPipe,
    WordCloudComponent,
    GroupedHbarComponent,
    AreaLineChartComponent,
    SmallBarChartComponent,
    StatusCodesComponent,
    HelpCenterComponent,
    BulletChartCardComponent,
    HierarchicalGridComponent,
    SlopeChartComponent,
    HBarChartComponent,
    BasicHeatmapCardComponent,
    KpiHeatMapCardComponent,
    MultiLinechartComponent,
    ControlChartCardComponent,
    HelpInformationComponent,
    TreeMenuComponent,
    PaymentRecordDetailsComponent,
    StackedAraeBarComponent,
    UnderScoreDetailsComponent,
    // VennChartCardComponent,
    CustomKpiComponent,
    // TerritoryMapComponent,
    WidgetDetailCardComponent,
    WidgetSummaryGridComponent,
    PieChartCardComponent,
    WidgetSummaryGridComponent,
    WidgetDetailCardComponent,
    KpiAlertsComponent,
    DataGridEditingComponent,
    ClusterBarchartCardComponent,
    searchTextPipe,
    IRHeatMapCardComponent,
    ValidationInputComponent,
    BasicGridListComponent,
    ValueBtnComponent,
    NestedWidgetComponent,
    OperationMonitorComponent,
    WidgetPaymentSummaryComponent,
    InitcapPipe,
    CommentsGridComponent
  ],
  imports: [
    WidgetDirective,
    CommonModule,
    GridsterModule,
    NgMultiSelectDropDownModule,
    FormsModule,
    ReactiveFormsModule,
    MatComponentsModule,
    FormlyModule,
    SharedComponentModule,
    AgGridModule,
    OverlayModule,
    MatExpansionModule,
    MatIconModule,
    MatSlideToggleModule,
    HttpClientModule,
    MatTabsModule,
    ChartsModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatRippleModule,
    MatTooltipModule,
    CdkAccordionModule,
    MatSlideToggleModule,
    MatRadioModule,
    LoaderToastModuleModule,
    MatMenuModule,
    CdkMenuModule,
    // DUCModule,
    MatProgressBarModule,
    StatusOverviewWidget,
     DispenseMapComponent,
    AngularResizeElementModule,
    // StatusTableModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatChipsModule ,TooltipComponent,
    ScrollingModule,
    WidgetTextNotesComponent,
    AlertButton,
    FilterButton,
    ProgressIndefinite,
    AlertsSubscriptionModule,
    TextFieldModule
  ],
  exports: [
    Widget1kpiCardComponent,
    Widget2kpiCardComponent,
    WidgetHtmlNotesComponent,
    WidgetDonutChartCardComponent,
    WidgetLinkCardComponent,
    WidgetTimeCycleComponent,
    WidgetPivotGridComponent,
    WidgetDataGridComponent,
    WidgetHeatmapChartComponent,
    WidgetLineChartComponent,
    WidgetBarChartCardComponent,
    GridPageComponent,
    FilterComponent,
    FilterBadgesComponent,
    PatientCardComponent,
    PatientJourneyCardComponent,
    WidgetBubbleChartComponent,
    PatientPipelineComponent,
    WidgetSparklineBarChartComponent,
    WidgetComboBarChartComponent,
    WidgetPrescriberAccordinComponent,
    StatusCodesComponent,
    HelpCenterComponent,
    PatientGridListComponent,
    KpiHeatMapCardComponent,
    HierarchicalGridComponent,
    PaymentRecordDetailsComponent,
    UnderScoreDetailsComponent,
    InfoIconComponent,
    WidgetSummaryGridComponent,
    WidgetDetailCardComponent,MatProgressBarModule,
    WidgetDetailCardComponent,
    PieChartCardComponent,
    KpiAlertsComponent,
    ValidationInputComponent,
    ValueBtnComponent,
    DataGridEditingComponent,
    NestedWidgetComponent,
    OperationMonitorComponent,
    WidgetPaymentSummaryComponent,CommentsGridComponent
  ],
  providers: [WidgetService, { provide: HTTP_INTERCEPTORS, useClass: MyInterceptor, multi: true },],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  
})
export class WidgetModule { }