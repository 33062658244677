<!-- Widget-Container -->
<loading *ngIf="loader" type="kpi"></loading>
<div class="container-fluid widget-container" *ngIf="item.config">
  <div *ngIf="item.config?.['direction_so_for']?.['value'] == 'horizontal'">
    <div class="row" *ngIf="customHeader"> 
      <div class="d-flex">
        <div *ngIf="item.config?.['icon_visibility_flag']['name']!='No'">
          <mat-icon style="width: 35px; height: 35px;" [svgIcon]="item.config['icon']?.['name']" [style.color]="item.config['icon_color']"></mat-icon>
        </div>
        <div style="width: 100%;">
          <div style="width: 100%;margin: 10px 0 15px 15px;" class="fontColor fontColor1" *ngIf="customHeader?.['headerName']=='1'" [ngStyle]="{'font-size': customHeader?.['headerFontSize'] +'px', 'color': customHeader?.['headerColor'], 'font-weight': customHeader?.['headerFontWeight']}">{{customHeader?.['headerNameInput'] || 'Header name'}}</div>
          <div style="width: 100%;margin: 10px 0 15px 15px;" class="fontColor fontColor1" *ngIf="customHeader?.['headerName']=='2'" [ngStyle]="{'font-size': customHeader?.['headerFontSize'] +'px', 'color': customHeader?.['headerColor'], 'font-weight': customHeader?.['headerFontWeight']}">{{ headerName || customHeader?.['headerNameInput']}}</div>
          <div class="d-flex just justify-content-between mt-2" style="width: 100%;margin-left: 15px;">
            <div *ngFor="let data of item.config?.kpi_header_value?.controls; let last = last;" class="d-flex spaceFor">
              <div>
                <div class="fontColor fontColor2" style="white-space: nowrap;margin-top: 0 !important;" *ngIf="!loader && data.swapText==false" [style.color]="data?.subHeaderColor || '#000'" [ngStyle]="{'font-size': data.subHeaderFont +'px', 'font-weight': data?.subHeaderFontWeight}">{{dbData?.[data?.linkName] || ''}}</div>
                <div class="fontColor fontColor3" style="margin-top: -5px !important;">
                  <div class="fontColor fontColor3 d-flex" *ngIf="!loader">
                    <!-- Prefix Name -->
                    <span *ngIf="data?.isPrefix" [style.color]="data?.prefixNameColor || '#000'"
                          [ngStyle]="{'font-size': data?.prefixNameFont + 'px', 'font-weight': data?.prefixNameFontWeight, 'white-space': 'pre-wrap'}">
                      <span *ngIf="data?.prefixNameType == '1'">{{data?.prefixName}}</span>
                      <span *ngIf="data?.prefixNameType == '2'">{{dbData ? dbData?.[data.prefixNameDrop] : ''}}</span>
                    </span>
                  
                    <!-- Link Data -->
                    <span [style.color]="data?.linkColor || '#000'"
                          [ngStyle]="{'font-size': data?.linkFont + 'px', 'font-weight': data?.linkFontWeight, 'white-space': 'pre-wrap'}">
                      <span *ngIf="data?.formatType === 'number'">{{ dbData?.[data?.linkPoint] || 0 | numberFormat }}</span>
                      <span *ngIf="data?.formatType === 'currency'">{{ dbData?.[data?.linkPoint] || 0 | customCurrency }}</span>
                      <span *ngIf="data?.formatType === 'string'">{{ dbData?.[data?.linkPoint] || '' }}</span>
                    </span>
                  
                    <!-- Suffix Name -->
                    <span *ngIf="data?.isSuffix" [style.color]="data?.suffixNameColor || '#000'"
                          [ngStyle]="{'font-size': data?.suffixNameFont + 'px', 'font-weight': data?.suffixNameFontWeight, 'white-space': 'pre-wrap'}">
                      <span *ngIf="data?.suffixNameType == '1'">{{data?.suffixName}}</span>
                      <span *ngIf="data?.suffixNameType == '2'">{{dbData ? dbData?.[data?.suffixNameDrop] : ''}}</span>
                    </span>
                  </div>
                  <!-- <div  *ngIf="loader" [style.border-top]="  '5px solid '+item.config?.['icon_color']" class="loader"></div> -->
                </div>
                <div class="fontColor fontColor2" style="white-space: nowrap;" *ngIf="!loader && data?.swapText==true" [style.color]="data?.subHeaderColor || '#000'" [ngStyle]="{'font-size': data?.subHeaderFont +'px', 'font-weight': data?.subHeaderFontWeight}">{{dbData? dbData?.[data?.linkName] : '' || ''}}ok</div>
    
              </div>
              <div *ngIf="!last" class="vl"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="item.config?.['direction_so_for']?.['value'] == 'vertical'">
    
    <div class="d-flex">
      <div *ngIf="customHeader">
        <div class="d-flex align-items-center" *ngIf="item.config?.['icon_visibility_flag']?.['name']!='No'">
          <mat-icon style="width: 35px; height: 35px;" [svgIcon]="item.config['icon']['name']" [style.color]="item.config['icon_color']"></mat-icon>
          <!-- <div class="fontColor fontColor1" style="margin-top: 10px;" [ngStyle]="{'font-size': item.config?.['font_size'] +'px'}" >{{item.config['header_name'] || 'Header name'}}</div> -->
          <div style="width: 100%;margin-left: 15px;margin-top: 0;" class="fontColor fontColor1" *ngIf="customHeader?.['headerName']=='1'" [ngStyle]="{'font-size': customHeader?.['headerFontSize'] +'px', 'color': customHeader?.['headerColor'], 'font-weight': customHeader?.['headerFontWeight']}">{{customHeader?.['headerNameInput'] || 'Header name'}}</div>
          <div style="width: 100%;margin-left: 15px;margin-top: 0;" class="fontColor fontColor1" *ngIf="customHeader?.['headerName']=='2'" [ngStyle]="{'font-size': customHeader?.['headerFontSize'] +'px', 'color': customHeader?.['headerColor'], 'font-weight': customHeader?.['headerFontWeight']}">{{headerName || customHeader?.['headerNameInput']}}</div>
        </div>
        <!-- {{845978542 | numberFormat}} -->
         <div class="d-flex align-items-center" style="width: 100%;">
           <div style="width: 35px; height: 35px;"></div>
           <div style="width: 100%;margin-left: 15px;">
             <div *ngFor="let data of item.config?.kpi_header_value?.controls; let last = last;" class="d-flex align-items-center">
               
               <div class="fontColor fontColor2 verFontColor1" *ngIf="!loader && data?.swapText==true" [style.color]="data?.subHeaderColor || '#000'" [ngStyle]="{'font-size': data?.subHeaderFont +'px', 'font-weight': data?.subHeaderFontWeight}">{{dbData?.[data?.linkName] || ''}}</div>
                <div class="fontColor fontColor3 d-flex" *ngIf="!loader">
                  <!-- Prefix Name -->
                  <span *ngIf="data?.isPrefix" [style.color]="data?.prefixNameColor || '#000'"
                        [ngStyle]="{'font-size': data?.prefixNameFont + 'px', 'font-weight': data?.prefixNameFontWeight, 'white-space': 'pre-wrap'}">
                    <span *ngIf="data?.prefixNameType == '1'">{{data?.prefixName}}</span>
                    <span *ngIf="data?.prefixNameType == '2'">{{dbData ? dbData?.[data.prefixNameDrop] : ''}}</span>
                  </span>
                
                  <!-- Link Data -->
                  <span [style.color]="data?.linkColor || '#000'"
                        [ngStyle]="{'font-size': data?.linkFont + 'px', 'font-weight': data?.linkFontWeight, 'white-space': 'pre-wrap'}">
                    <span *ngIf="data?.formatType === 'number'">{{ dbData?.[data?.linkPoint] || 0 | numberFormat }}</span>
                    <span *ngIf="data?.formatType === 'currency'">{{ dbData?.[data?.linkPoint] || 0 | customCurrency }}</span>
                    <span *ngIf="data?.formatType === 'string'">{{ dbData?.[data?.linkPoint] || '' }}</span>
                  </span>
                
                  <!-- Suffix Name -->
                  <span *ngIf="data?.isSuffix" [style.color]="data?.suffixNameColor || '#000'"
                        [ngStyle]="{'font-size': data?.suffixNameFont + 'px', 'font-weight': data?.suffixNameFontWeight, 'white-space': 'pre-wrap'}">
                    <span *ngIf="data?.suffixNameType == '1'">{{data?.suffixName}}</span>
                    <span *ngIf="data?.suffixNameType == '2'">{{dbData ? dbData?.[data?.suffixNameDrop] : ''}}</span>
                  </span>
                </div>
               <!-- <div  *ngIf="loader" [style.border-top]="  '5px solid '+item.config['icon_color']" class="loader"></div> -->
               <div class="fontColor fontColor2 verFontColor" *ngIf="!loader && data.swapText==false" [style.color]="data.subHeaderColor || '#000'" [ngStyle]="{'font-size': data.subHeaderFont +'px', 'font-weight': data.subHeaderFontWeight}">{{dbData ? dbData?.[data.linkName] : '' || ''}}</div>
               
             </div>
           </div>
         </div>
      </div>
    </div>
  </div>
</div>

<!-- Top Right Opacity Icon -->
<div class="large-icon-containter" *ngIf="item.config" id="setting">
  <img class="info_img"  matTooltip="Help" (click)="getInfoDetail()"  [matMenuTriggerFor]="info"   type="button"  src="./../../../../assets/svgIcons/info_circle.svg"/>
  <!-- <span (mouseover)="isOpen = !isOpen" (mouseout)="isOpen = !isOpen"  type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin" class="stroke_text">i</span> -->
  
  <mat-menu  #info="matMenu">
    <div class="card" style="min-width: 300px;border: none;width: auto;" (click)="$event.stopPropagation()">
      <div class="card-header d-flex justify-content-between align-items-center" style="border-bottom: none;background: #ddd;">
        <span style="font-weight: 600;">{{infoData?.info_header}}</span>

        <span (click)="closeMenu()" mat-dialog-close> 
            <svg width="16" height="16" style="width: 12px !important;height: 12px !important;" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.9501 0.409871L8 5.35998L3.04989 0.409871C2.5034 -0.136624 1.61634 -0.136624 1.06985 0.409871L0.409871 1.06985C-0.136624 1.61634 -0.136624 2.5034 0.409871 3.04989L5.35998 8L0.409871 12.9501C-0.136624 13.4966 -0.136624 14.3837 0.409871 14.9302L1.06985 15.5901C1.61634 16.1366 2.5034 16.1366 3.04989 15.5901L8 10.64L12.9501 15.5901C13.4966 16.1366 14.3837 16.1366 14.9302 15.5901L15.5901 14.9302C16.1366 14.3837 16.1366 13.4966 15.5901 12.9501L10.64 8L15.5901 3.04989C16.1366 2.5034 16.1366 1.61634 15.5901 1.06985L14.9302 0.409871C14.3837 -0.136624 13.4966 -0.136624 12.9501 0.409871Z" fill="#1363DF" />
            </svg>
        </span>
      </div>

      <div class="card-body">
        <div [innerHtml]="(infoData?.info_text) || '' "></div>
      </div>
    </div> 
  </mat-menu>
</div>

<div class="bottom-icon-container" *ngIf="item.config && item.config?.['icon_visibility_flag']?.['name']!='No'">
  <mat-icon class="large-icon" [svgIcon]="item.config['icon']['name']">
  </mat-icon>
</div>